import { SectionsType, Sections, AllPuzzlesType } from '../puzzle_defs';
import { APHABET_DAY_COPY } from './copyable_content/aphabet_day';
import { DOT_YOUR_IS_DAY_COPY } from './copyable_content/dot_your_is_day';
import { ANSWER_THE_PHONE_LIKE_BUDDY_THE_ELF_DAY_COPY } from './copyable_content/answer_the_phone_like_buddy_the_elf_day';
import { DEWEY_DECIMAL_SYSTEM_DAY_COPY } from './copyable_content/dewey_decimal_system_day';
import { NATIONAL_ROOTS_DAY_COPY } from './copyable_content/national_roots_day';
import NATIONAL_DING_A_LING_DAY from './puzzle_pdfs/national_ding_a_ling_day.pdf';
import NATIONAL_BROWNIE_DAY from './puzzle_pdfs/national_brownie_day.pdf';
import NATIONAL_CHOCOLATE_COVERED_ANYTHING_DAY from './puzzle_pdfs/national_chocolate_covered_anything_day.pdf';
import ANSWER_THE_PHONE_LIKE_BUDDY_THE_ELF_DAY from './puzzle_pdfs/answer_the_phone_like_buddy_the_elf_day.pdf';
import INTERNATIONAL_CHEETAH_DAY from './puzzle_pdfs/international_cheetah_day.pdf';
import DEWEY_DECIMAL_SYSTEM_DAY from './puzzle_pdfs/dewey_decimal_system_day.pdf';
import NATIONAL_MATHEMATICS_DAY from './puzzle_pdfs/national_mathematics_day.pdf';
import DOT_YOUR_IS_DAY from './puzzle_pdfs/dot_your_is_day.pdf';
import NATIONAL_ROOTS_DAY from './puzzle_pdfs/national_roots_day.pdf';
import APHABET_DAY from './puzzle_pdfs/aphabet_day.pdf';
import FUEL_SYNTHESIS from './puzzle_pdfs/fuel_synthesis.pdf';
import NATIONAL_DING_A_LING_DAY_SOLUTION from './solution_pdfs/national_ding_a_ling_day_solution.pdf';
import NATIONAL_BROWNIE_DAY_SOLUTION from './solution_pdfs/national_brownie_day_solution.pdf';
import NATIONAL_CHOCOLATE_COVERED_ANYTHING_DAY_SOLUTION from './solution_pdfs/national_chocolate_covered_anything_day_solution.pdf';
import ANSWER_THE_PHONE_LIKE_BUDDY_THE_ELF_DAY_SOLUTION from './solution_pdfs/answer_the_phone_like_buddy_the_elf_day_solution.pdf';
import INTERNATIONAL_CHEETAH_DAY_SOLUTION from './solution_pdfs/international_cheetah_day_solution.pdf';
import DEWEY_DECIMAL_SYSTEM_DAY_SOLUTION from './solution_pdfs/dewey_decimal_system_day_solution.pdf';
import NATIONAL_MATHEMATICS_DAY_SOLUTION from './solution_pdfs/national_mathematics_day_solution.pdf';
import DOT_YOUR_IS_DAY_SOLUTION from './solution_pdfs/dot_your_is_day_solution.pdf';
import NATIONAL_ROOTS_DAY_SOLUTION from './solution_pdfs/national_roots_day_solution.pdf';
import APHABET_DAY_SOLUTION from './solution_pdfs/aphabet_day_solution.pdf';
import FUEL_SYNTHESIS_SOLUTION from './solution_pdfs/fuel_synthesis_solution.pdf';
import WRAP_UP_2023 from './solution_pdfs/wrap_up_and_solutions_2023.pdf';

// For the current hunt, we can either add the PDFs (like the archived hunts)
// or link to the google docs.

export const ALL_PUZZLES_2023: AllPuzzlesType = {
    ANSWER_THE_PHONE_LIKE_BUDDY_THE_ELF_DAY: {
        name: 'Answer the Phone Like Buddy the Elf Day',
        link: ANSWER_THE_PHONE_LIKE_BUDDY_THE_ELF_DAY,
        solutionLink: ANSWER_THE_PHONE_LIKE_BUDDY_THE_ELF_DAY_SOLUTION,
        formatFriendlyString: ANSWER_THE_PHONE_LIKE_BUDDY_THE_ELF_DAY_COPY,
    },
    APHABET_DAY: {
        name: "A'phabet Day",
        link: APHABET_DAY,
        solutionLink: APHABET_DAY_SOLUTION,
        formatFriendlyString: APHABET_DAY_COPY,
    },
    DEWEY_DECIMAL_SYSTEM_DAY: {
        name: 'Dewey Decimal System Day',
        link: DEWEY_DECIMAL_SYSTEM_DAY,
        solutionLink: DEWEY_DECIMAL_SYSTEM_DAY_SOLUTION,
        formatFriendlyString: DEWEY_DECIMAL_SYSTEM_DAY_COPY,
    },
    DOT_YOUR_IS_DAY: {
        name: "Dot Your I's Day",
        link: DOT_YOUR_IS_DAY,
        solutionLink: DOT_YOUR_IS_DAY_SOLUTION,
        formatFriendlyString: DOT_YOUR_IS_DAY_COPY,
    },
    INTERNATIONAL_CHEETAH_DAY: {
        name: 'International Cheetah Day',
        link: INTERNATIONAL_CHEETAH_DAY,
        solutionLink: INTERNATIONAL_CHEETAH_DAY_SOLUTION,
    },
    NATIONAL_BROWNIE_DAY: {
        name: 'National Brownie Day',
        link: NATIONAL_BROWNIE_DAY,
        solutionLink: NATIONAL_BROWNIE_DAY_SOLUTION,
    },
    NATIONAL_CHOCOLATE_COVERED_ANYTHING_DAY: {
        name: 'National Chocolate Covered Anything Day',
        link: NATIONAL_CHOCOLATE_COVERED_ANYTHING_DAY,
        solutionLink: NATIONAL_CHOCOLATE_COVERED_ANYTHING_DAY_SOLUTION,
    },
    NATIONAL_DING_A_LING_DAY: {
        name: 'National Ding-a-ling Day',
        link: NATIONAL_DING_A_LING_DAY,
        solutionLink: NATIONAL_DING_A_LING_DAY_SOLUTION,
    },
    NATIONAL_MATHEMATICS_DAY: {
        name: 'National Mathematics Day',
        link: NATIONAL_MATHEMATICS_DAY,
        solutionLink: NATIONAL_MATHEMATICS_DAY_SOLUTION,
    },
    NATIONAL_ROOTS_DAY: {
        name: 'National Roots Day',
        link: NATIONAL_ROOTS_DAY,
        solutionLink: NATIONAL_ROOTS_DAY_SOLUTION,
        formatFriendlyString: NATIONAL_ROOTS_DAY_COPY,
    },
    FUEL_SYNTHESIS: {
        name: 'Fuel Synthesis',
        link: FUEL_SYNTHESIS,
        solutionLink: FUEL_SYNTHESIS_SOLUTION,
    },
};

const SECTIONS_2023: SectionsType = {
    [Sections.EASY]: {
        name: Sections.EASY,
        points: 50,
        puzzles: [
            ALL_PUZZLES_2023.NATIONAL_DING_A_LING_DAY,
            ALL_PUZZLES_2023.NATIONAL_BROWNIE_DAY,
            ALL_PUZZLES_2023.NATIONAL_CHOCOLATE_COVERED_ANYTHING_DAY,
        ],
    },
    [Sections.MEDIUM]: {
        name: Sections.MEDIUM,
        points: 100,
        puzzles: [
            ALL_PUZZLES_2023.ANSWER_THE_PHONE_LIKE_BUDDY_THE_ELF_DAY,
            ALL_PUZZLES_2023.INTERNATIONAL_CHEETAH_DAY,
            ALL_PUZZLES_2023.DEWEY_DECIMAL_SYSTEM_DAY,
            ALL_PUZZLES_2023.NATIONAL_MATHEMATICS_DAY,
        ],
    },
    [Sections.HARD]: {
        name: Sections.HARD,
        points: 150,
        puzzles: [
            ALL_PUZZLES_2023.DOT_YOUR_IS_DAY,
            ALL_PUZZLES_2023.NATIONAL_ROOTS_DAY,
            ALL_PUZZLES_2023.APHABET_DAY,
        ],
    },
    [Sections.META]: {
        name: Sections.META,
        points: 200,
        puzzles: [ALL_PUZZLES_2023.FUEL_SYNTHESIS],
    },
    [Sections.WRAP_UP]: {
        solutions: WRAP_UP_2023,
        leaderboard:
            'https://docs.google.com/spreadsheets/d/1mka2JgLD9orMyzY0HlFPQVqtJu2vJ4s-7E3A1My1o1o/edit?usp=sharing',
    },
};

export default SECTIONS_2023;
