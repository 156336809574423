/** @jsxImportSource @emotion/react */
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import { PAGE_NAMES } from '../../data/page_structure';
import { SectionType, Sections } from '../../data/puzzles/puzzle_defs';
import PuzzleSections from '../../components/PuzzleDisplay/PuzzleSections';
import { useCurrentPuzzleSections } from '../../hooks/useCurrentPuzzleSections';
import { useFirebaseFunction } from '../../hooks/useFirebaseFunction';
import { useCallback, useEffect, useState } from 'react';
import { useAuthUser } from '../../hooks/useAuthUser';
import { AllPuzzleStats } from '../../utils/puzzle_stats';
import { isMetaAcccessible, isPuzzlehuntOver } from '../../utils/flagging';
import { css } from '@emotion/react';
import { CURRENT_YEAR } from '../../data/puzzlehunt_details';
import WrapUpSection from '../../components/PuzzleDisplay/WrapUpSection';

const CSS_PUZZLES_WRAPPER = css({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
});

const CSS_PUZZLEHUNT_OVER_TEXT = css({
    display: 'flex',
    justifyContent: 'center',
    fontSize: '20px',
});

function Puzzles() {
    const [completedPuzzleNames, setCompletedPuzzleNames] = useState<string[]>();
    const [allPuzzleStats, setAllPuzzleStats] = useState<AllPuzzleStats>();
    const authUser = useAuthUser();
    const { puzzleSections } = useCurrentPuzzleSections();

    const {
        callFunction: getSolvedPuzzlesFn,
        isRequestInProgress: isGetSolvedPuzzlesRequestInProgress,
    } = useFirebaseFunction('getSolvedPuzzles');
    const {
        callFunction: getPuzzleStatsFn,
        isRequestInProgress: isGetPuzzleStatsRequestInProgress,
    } = useFirebaseFunction('getPuzzleStats');

    const loadSolvedAnswers = useCallback(
        (username: string) => {
            if (!isGetSolvedPuzzlesRequestInProgress && completedPuzzleNames === undefined) {
                getSolvedPuzzlesFn({
                    username: username,
                })
                    .then((getSolvedPuzzlesResponse) => {
                        setCompletedPuzzleNames(
                            Object.keys(getSolvedPuzzlesResponse.data.solvedPuzzles),
                        );
                    })
                    .catch((error) => {
                        console.error('Error while loading solved puzzles:', error);
                        setCompletedPuzzleNames([]);
                    });
            }
        },
        [completedPuzzleNames, getSolvedPuzzlesFn, isGetSolvedPuzzlesRequestInProgress],
    );

    const loadPuzzleStats = useCallback(() => {
        if (!isGetPuzzleStatsRequestInProgress && allPuzzleStats === undefined) {
            getPuzzleStatsFn({})
                .then((getPuzzleStatsResponse) => {
                    const retrievedPuzzleStats = getPuzzleStatsResponse.data.puzzleStats;
                    setAllPuzzleStats(retrievedPuzzleStats ? retrievedPuzzleStats : {});
                })
                .catch((error) => {
                    console.error('Error while loading solved puzzles:', error);
                    setAllPuzzleStats({});
                });
        }
    }, [allPuzzleStats, getPuzzleStatsFn, isGetPuzzleStatsRequestInProgress]);

    useEffect(() => {
        loadPuzzleStats();
    }, [loadPuzzleStats]);

    useEffect(() => {
        if (authUser?.uid) {
            loadSolvedAnswers(authUser.uid);
        }
    }, [authUser, loadSolvedAnswers]);

    let relevantSections = [Sections.EASY, Sections.MEDIUM, Sections.HARD];
    if (puzzleSections && Sections.META in puzzleSections && isMetaAcccessible(authUser)) {
        relevantSections = [...relevantSections, Sections.META];
    }

    return (
        <PageLayout>
            <Title>{PAGE_NAMES.PUZZLES}</Title>
            <div css={CSS_PUZZLES_WRAPPER}>
                {isPuzzlehuntOver() && (
                    <div css={CSS_PUZZLEHUNT_OVER_TEXT}>
                        <b>
                            The {CURRENT_YEAR} puzzlehunt is over, thank you to everyone who
                            participated!
                        </b>
                    </div>
                )}
                {puzzleSections && (
                    <PuzzleSections
                        sections={relevantSections.map((relevantSection) => {
                            const processedSections = {
                                ...(puzzleSections[relevantSection] as SectionType),
                            };
                            return processedSections;
                        })}
                        allPuzzleStats={allPuzzleStats}
                        completedPuzzles={completedPuzzleNames}
                    />
                )}
                {isPuzzlehuntOver() && puzzleSections?.[Sections.WRAP_UP] && (
                    <WrapUpSection wrapUpInfo={puzzleSections[Sections.WRAP_UP]} />
                )}
            </div>
        </PageLayout>
    );
}

export default Puzzles;
