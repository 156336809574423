import { SectionsType, Sections, AllPuzzlesType } from '../puzzle_defs';
import DECADES_AWAY from './puzzle_pdfs/decades_away.pdf';
import DIVINE_ASSOCIATIONS from './puzzle_pdfs/divine_associations.pdf';
import HIEROGLYPHIC_CRYPTARITHMIC from './puzzle_pdfs/hieroglyphic_cryptarithmic.pdf';
import POETRY_FOR_NEANDERTHALS from './puzzle_pdfs/poetry_for_neanderthals.pdf';
import REVOLUTIONARY_OPPOSITES from './puzzle_pdfs/revolutionary_opposites.pdf';
import SLIPPING_AND_SLIDING from './puzzle_pdfs/slipping_and_sliding.pdf';
import THE_PILLOW_BOOK from './puzzle_pdfs/the_pillow_book.pdf';
import WALK_THE_CLANK from './puzzle_pdfs/walk_the_clank.pdf';
import SIGNALS_THROUGH_TIME from './puzzle_pdfs/signals_through_time.pdf';

import DECADES_AWAY_SOLUTION from './solutions_pdfs/decades_away_solution.pdf';
import DIVINE_ASSOCIATIONS_SOLUTION from './solutions_pdfs/divine_associations_solution.pdf';
import HIEROGLYPHIC_CRYPTARITHMIC_SOLUTION from './solutions_pdfs/hieroglyphic_cryptarithmic_solution.pdf';
import POETRY_FOR_NEANDERTHALS_SOLUTION from './solutions_pdfs/poetry_for_neanderthals_solution.pdf';
import REVOLUTIONARY_OPPOSITES_SOLUTION from './solutions_pdfs/revolutionary_opposites_solution.pdf';
import SLIPPING_AND_SLIDING_SOLUTION from './solutions_pdfs/slipping_and_sliding_solution.pdf';
import THE_PILLOW_BOOK_SOLUTION from './solutions_pdfs/the_pillow_book_solution.pdf';
import WALK_THE_CLANK_SOLUTION from './solutions_pdfs/walk_the_clank_solution.pdf';
import SIGNALS_THROUGH_TIME_SOLUTION from './solutions_pdfs/signals_through_time_solution.pdf';
import WRAP_UP_2024 from './wrap_up_2024.pdf';

// For the current hunt, we can either add the PDFs (like the archived hunts)
// or link to the google docs.;

export const ALL_PUZZLES_2024: AllPuzzlesType = {
    DECADES_AWAY: {
        name: 'Decades Away',
        link: DECADES_AWAY,
        spreadsheetLink:
            'https://docs.google.com/spreadsheets/d/1v4sceaAzL8pVkpTMr9uIFZnYnX9gj0sGShjrI-CYp4I/edit?gid=2029437432#gid=2029437432',
        solutionLink: DECADES_AWAY_SOLUTION,
    },
    DIVINE_ASSOCIATIONS: {
        name: 'Divine Associations',
        link: DIVINE_ASSOCIATIONS,
        spreadsheetLink:
            'https://docs.google.com/spreadsheets/d/1v4sceaAzL8pVkpTMr9uIFZnYnX9gj0sGShjrI-CYp4I/edit?gid=1499457642#gid=1499457642',
        solutionLink: DIVINE_ASSOCIATIONS_SOLUTION,
    },
    HIEROGLYPHIC_CRYPTARITHMIC: {
        name: 'Hieroglyphic Cryptarithmic',
        link: HIEROGLYPHIC_CRYPTARITHMIC,
        spreadsheetLink:
            'https://docs.google.com/spreadsheets/d/1v4sceaAzL8pVkpTMr9uIFZnYnX9gj0sGShjrI-CYp4I/edit?gid=1633910296#gid=1633910296',
        solutionLink: HIEROGLYPHIC_CRYPTARITHMIC_SOLUTION,
    },
    POETRY_FOR_NEANDERTHALS: {
        name: 'Poetry for Neanderthals',
        link: POETRY_FOR_NEANDERTHALS,
        spreadsheetLink:
            'https://docs.google.com/spreadsheets/d/1v4sceaAzL8pVkpTMr9uIFZnYnX9gj0sGShjrI-CYp4I/edit?gid=1357752156#gid=1357752156',
        solutionLink: POETRY_FOR_NEANDERTHALS_SOLUTION,
    },
    REVOLUTIONARY_OPPOSITES: {
        name: 'Revolutionary Opposites',
        link: REVOLUTIONARY_OPPOSITES,
        spreadsheetLink:
            'https://docs.google.com/spreadsheets/d/1v4sceaAzL8pVkpTMr9uIFZnYnX9gj0sGShjrI-CYp4I/edit?gid=1528126019#gid=1528126019',
        solutionLink: REVOLUTIONARY_OPPOSITES_SOLUTION,
    },
    SLIPPING_AND_SLIDING: {
        name: 'Slipping and Sliding',
        link: SLIPPING_AND_SLIDING,
        spreadsheetLink:
            'https://docs.google.com/spreadsheets/d/1v4sceaAzL8pVkpTMr9uIFZnYnX9gj0sGShjrI-CYp4I/edit?gid=504031287#gid=504031287',
        solutionLink: SLIPPING_AND_SLIDING_SOLUTION,
    },
    THE_PILLOW_BOOK: {
        name: 'The Pillow Book',
        link: THE_PILLOW_BOOK,
        spreadsheetLink:
            'https://docs.google.com/spreadsheets/d/1v4sceaAzL8pVkpTMr9uIFZnYnX9gj0sGShjrI-CYp4I/edit?gid=78250583#gid=78250583',
        solutionLink: THE_PILLOW_BOOK_SOLUTION,
    },
    WALK_THE_CLANK: {
        name: 'Walk the Clank',
        link: WALK_THE_CLANK,
        spreadsheetLink:
            'https://docs.google.com/spreadsheets/d/1v4sceaAzL8pVkpTMr9uIFZnYnX9gj0sGShjrI-CYp4I/edit?gid=1712893449#gid=1712893449',
        solutionLink: WALK_THE_CLANK_SOLUTION,
    },
    SIGNALS_THROUGH_TIME: {
        name: 'Signals Through Time',
        link: SIGNALS_THROUGH_TIME,
        spreadsheetLink:
            'https://docs.google.com/spreadsheets/d/10z_CJKLap9NdXLFQxg0EqEJ-RaqgYAxAng0UhJMIq7U/edit?usp=sharing',
        isMeta: true,
        solutionLink: SIGNALS_THROUGH_TIME_SOLUTION,
    },
};

const SECTIONS_2024: SectionsType = {
    [Sections.EASY]: {
        name: Sections.EASY,
        points: 50,
        puzzles: [ALL_PUZZLES_2024.WALK_THE_CLANK, ALL_PUZZLES_2024.REVOLUTIONARY_OPPOSITES],
    },
    [Sections.MEDIUM]: {
        name: Sections.MEDIUM,
        points: 100,
        puzzles: [ALL_PUZZLES_2024.SLIPPING_AND_SLIDING, ALL_PUZZLES_2024.POETRY_FOR_NEANDERTHALS],
    },
    [Sections.HARD]: {
        name: Sections.HARD,
        points: 150,
        puzzles: [
            ALL_PUZZLES_2024.HIEROGLYPHIC_CRYPTARITHMIC,
            ALL_PUZZLES_2024.THE_PILLOW_BOOK,
            ALL_PUZZLES_2024.DIVINE_ASSOCIATIONS,
            ALL_PUZZLES_2024.DECADES_AWAY,
        ],
    },
    [Sections.META]: {
        name: Sections.META,
        points: 200,
        puzzles: [ALL_PUZZLES_2024.SIGNALS_THROUGH_TIME],
    },
    [Sections.WRAP_UP]: {
        solutions: WRAP_UP_2024,
        leaderboard: '/teams',
    },
};

export default SECTIONS_2024;
