export const DEWEY_DECIMAL_SYSTEM_DAY_COPY = `First, word in the library is that the books are mislabeled.				
				
There are Three Holes in a Pretzel: An Introduction to Topology	Are You Financially Stable? Introduction to Microeconomics	Two Paradigms of Computer Languages	Types of Public Libraries in the US	Of Age: Psychological Research on Growing Adolescents
001.98	003.52	005.13	027.47	155.51
Nonfiction and Education: Effective Use of Literature in the Classroom	Books Unearthed: Historical Records of Ancient Architecture	In God's Name: Religion in the Classroom	The Evolution of Fantasy: Myths and Folklores	Shelves of Policies: Democracy in the United States
174.25	199.66	200.71	248.85	320.97
Ones and Zeros: The Spread of Digital Information	That Calls for a Celebration: Social Customs at Mardi Gras	Match in Meaning: Linguistic Semantics	Their, There, They're: Understanding Grammar	Number Theory: Of Integers and Functions
345.73	394.25	401.43	428.24	493.10
Category 5: Catastrophic Hurricanes	And The Music Played On: A Melodic Journey Through History	Those Same Differences: American Cultural Diaspora	Which Foods Should I Put in My Body? A Guide to Eating Healthier	Don't Plagiarize: How to Cite Sources Properly
551.55	567.90	599.86	613.20	690.83
Use the Correct Tools: Guidebook for Household Cleaning	Library of Autonomy: The Emergence of Self-driving Vehicles	Thing of Destiny: Unraveling Themes of Greek Literature	Melvil: An Autobiography	Site Seekers: Exploring the Wonders of Manchuria
759.03	782.14	880.93	888.01	915.18`;
