import { useEffect, useState } from 'react';
import { CURRENT_YEAR } from '../data/puzzlehunt_details';

/**
 * Import the current year's story from the data/ folder.
 */
export function useStory() {
    const [storyContent, setStoryContent] = useState();

    useEffect(() => {
        // Conditionally import the story from the current year.
        async function importDependencies() {
            try {
                let storyExport = await import(`../data/puzzles/${CURRENT_YEAR}/story_content.md`);
                setStoryContent(storyExport.default);
            } catch {
                return;
            }
        }

        importDependencies();
    }, []);

    return { storyContent };
}
