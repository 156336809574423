import { ALL_PUZZLES_2020 } from './2020/2020_puzzles';
import { ALL_PUZZLES_2021 } from './2021/2021_puzzles';
import { ALL_PUZZLES_2022 } from './2022/2022_puzzles';
import { ALL_PUZZLES_2023 } from './2023/2023_puzzles';
import { ALL_PUZZLES_2024 } from './2024/2024_puzzles';
import { PuzzleInfo } from './puzzle_defs';

export const PUZZLE_HIGHLIGHTS: { [year: string]: PuzzleInfo[] } = {
    '2024': [ALL_PUZZLES_2024.SLIPPING_AND_SLIDING],
    '2023': [ALL_PUZZLES_2023.APHABET_DAY, ALL_PUZZLES_2023.DEWEY_DECIMAL_SYSTEM_DAY],
    '2022': [ALL_PUZZLES_2022.VITAMIN_SEE, ALL_PUZZLES_2022.HOT_CHOCOLATE],
    '2021': [ALL_PUZZLES_2021.CLOSE_ENOUGH],
    '2020': [ALL_PUZZLES_2020.DANCING_DRAWINGS, ALL_PUZZLES_2020.WE_LOVE_TALKING_TURTLES],
};
