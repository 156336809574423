/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import LinkableRowContent from '../../components/Table/CustomRow/LinkableRowContent';
import Row from '../../components/Table/CustomRow/Row';
import { ARCHIVED_HUNT_MENU_OPTIONS, PAGE_NAMES } from '../../data/page_structure';
import { ARCHIVED_YEARS } from '../../data/puzzlehunt_details';
import { css } from '@emotion/react';

import { ReactComponent as AlienEmoji } from '../../assets/images/archive/alien.svg';
import { ReactComponent as CircusTentEmoji } from '../../assets/images/archive/circus_tent.svg';
import { ReactComponent as ClockEmoji } from '../../assets/images/archive/clock.svg';
import { ReactComponent as SnowflakeEmoji } from '../../assets/images/archive/snowflake.svg';
import { ReactComponent as PartyPopperEmoji } from '../../assets/images/archive/party_popper.svg';
import { ReactComponent as TshirtEmoji } from '../../assets/images/archive/tshirt.svg';

const ARCHIVE_YEAR_TO_EMOJI: {
    [year: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
    Highlights: PartyPopperEmoji,
    '2020': SnowflakeEmoji,
    '2021': TshirtEmoji,
    '2022': CircusTentEmoji,
    '2023': AlienEmoji,
    '2024': ClockEmoji,
};

const CSS_ROW_CONTENT = css({
    alignItems: 'center',
    display: 'flex',
    gap: '12px',
});

function Archive() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <PageLayout>
            <Title>{PAGE_NAMES.ARCHIVE}</Title>
            <div>
                {Object.entries(ARCHIVED_HUNT_MENU_OPTIONS).map(([pageName, { link }]) => {
                    const puzzlehuntYear = ARCHIVED_YEARS.find((year) => {
                        return pageName?.includes(year);
                    });
                    const EmojiComponent = ARCHIVE_YEAR_TO_EMOJI[puzzlehuntYear ?? pageName];

                    return (
                        <Row key={pageName}>
                            <LinkableRowContent
                                link={link}
                                setIsHovered={setIsHovered}
                                isHovered={isHovered}
                            >
                                <div css={CSS_ROW_CONTENT}>
                                    {EmojiComponent && (
                                        <EmojiComponent width="24px" height="24px" />
                                    )}
                                    {pageName}
                                </div>
                            </LinkableRowContent>
                        </Row>
                    );
                })}
            </div>
        </PageLayout>
    );
}

export default Archive;
