/** @jsxImportSource @emotion/react */
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/PageLayout/Title';
import { PAGE_NAMES } from '../../data/page_structure';
import { useMarkdown } from '../../hooks/useMarkdown';
import { CSS_IGNORE_TOP_CONTENT_PADDING } from '../../utils/content_page_css';
import { css } from '@emotion/react';
import { useStory } from '../../hooks/useStory';

const CSS_CENTER_ALIGNMENT = css({
    textAlign: 'center',
});

function Story() {
    const { storyContent } = useStory();

    const { html } = useMarkdown(storyContent ?? '');

    return (
        <PageLayout>
            <Title>{PAGE_NAMES.STORY}</Title>
            {storyContent && html ? (
                <div
                    dangerouslySetInnerHTML={{ __html: html }}
                    css={CSS_IGNORE_TOP_CONTENT_PADDING}
                />
            ) : (
                <div css={CSS_CENTER_ALIGNMENT}>The story is not available yet. Stay tuned!</div>
            )}
        </PageLayout>
    );
}

export default Story;
