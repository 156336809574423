export const APHABET_DAY_COPY = `X discovered Earth's video games and spent Noel day,
aka No"L" day, playing Tetris with opaque blocks that obscure your view.	
	
Click to watch X's Tetris run: https://youtu.be/nlWvL5LX5no	
	
If John Kennedy were an Anthony instead, abbrev...	Word with "slow" or "fun" + work week starter, for short?
Bearded mammal	Meta's all-in-one VR headsets
Spider's construction	Violent behavior, in British slang
A  ____ OR C OR D?	Sulfur + bishop protector and future queen, maybe?
Deep sorrow	Palindromic data?
Crush to a powder	Tabloid tell-all tale, but with the former partner swapped out for a golf ball support?
Thrill or boot	Aang or Roku, for example
Former measure of distance, around three miles	Opposite of "Woman Z"?
Aries animal	Take advantage of
"Nyah, nyah!" is one	One Piece clown
Loafing around like a pop star?	Cleans with a broom
Jet problem?	Fuel location for the army vehicle?`;
